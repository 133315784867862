.container {
    /*background-image: url("https://codelisa-nodelisa.s3.eu-central-1.amazonaws.com/file-name-1714075757667.png");  public klasörü içindeki arka plan resmi */
    background-image: url(./we10.jpg), linear-gradient(to bottom, rgba(0,0,0,0.5), rgba(0,0,0,0.5));
    background-blend-mode: overlay;
    background-size: cover;
    background-position: center;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}
body {
    overflow: hidden; /* Sayfanın scroll olmasını engellemek için */
    position: fixed;
    width: 100%;
}

.header {
    text-align: center;
    margin-bottom: 20px;
    margin-top: 60px;
    color: white;
    font-family: "Freehand", cursive;
    font-weight: 100;
    font-style: normal;
}

.content {
    width: 100%;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

input[type="file"] {
    display: block;
    width: 100%;
    margin-bottom: 10px;
}

button {
    width: 100%;
    background-color: #007bff;
    color: #fff;
    padding: 10px;
    border: none;
    cursor: pointer;
}

.name{
    margin: 0;
    font-size: 60px;
}
.tarih{
    margin-top: 0;
    margin-bottom: 5px;
    font-size: 20px;
}
.loading1div{
    height: 40px;
    width: 350px;
    text-align: center;
    background-color: rgba(0,0,0,0.5);
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: 40px;
    border-radius: 50px;
}

.loading1{
    font-family: "Thasadith", sans-serif;
    font-size: large;
    border-radius: 50px;
    color: #91805B;
}

.loading, .success, .error {
    text-align: center;
    margin-top: 20px;
}
.content {
    position: relative; /* Görseldeki butonu üst üste getirmek için */
}

.file-upload-label {
    background-color: #91805B;
    color: #fff;
    padding: 10px 15px;
    border-radius: 50px;
    cursor: pointer;
    width: 225px;
    text-align: center;
    font-family: "Thasadith", sans-serif;
}

.file-upload-input {
    position: absolute;
    opacity: 0;
    top: 0;
    left: 0;
}

.upload-button {
    position: fixed;
    bottom: 60px; /* İsteğinize göre ayarlayabilirsiniz */
    background-color: #767569;
    width: 400px;
    color: white;
    font-size: large;
    border-radius: 50px;
    cursor: pointer;
    font-family: "Thasadith", sans-serif;
}

.note-button {
    background-color: #91805B;
    width:250px;
    color: white;
    font-size: large;
    border-radius: 50px;
    cursor: pointer;
    font-family: "Thasadith", sans-serif;
}
.popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(255,255,255,0.8);
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    z-index: 9999;
}

.close {
    position: absolute;
    top: 0px;
    right: 10px;
    cursor: pointer;
}

.note-input {
    width: 100%;
    height: 100px;
    margin-bottom: 10px;
    background-color: rgba(255,255,255,0.9);
    border-style: none;
    font-family: "Thasadith", sans-serif;
    font-size: small;
}
.name-input {
    width: 100%;
    height: 20px;
    margin-bottom: 10px;
    background-color: rgba(255,255,255,0.9);
    border-color: white !important;
    font-family: "Thasadith", sans-serif;
    font-size: small;
}
.notegönder{
    background-color: #91805B;
    width:250px;
    color: white;
    font-size: large;
    border-radius: 50px;
    cursor: pointer;
    font-family: "Thasadith", sans-serif;
}